









































import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeIcon from '@/components/atoms/icons/Time.vue'
import DiffIcon from '@/components/atoms/icons/Diff.vue'
import LinkBtn from '@/components/atoms/btns/Link.vue'
import Heading from '@/components/atoms/Heading.vue'
import Poster from '@/components/atoms/Poster.vue'

@Component({
  components: {
    TimeIcon,
    DiffIcon,
    LinkBtn,
    Heading,
    Poster,
  },
})
export default class RecipeWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean

  get ratio() {
    const { image } = this.model
    return image.height / image.width
  }
}
