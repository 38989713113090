import { Getter } from 'vuex-class'
import { Component, Prop } from 'vue-property-decorator'
import View from '@/mixins/View'

@Component
export default class Recipe extends View {
  @Getter('recipes/fetched') fetched!: boolean

  @Prop() recipe!: string

  async created() {
    this.$state.fetching = true

    const needsFetch = !this.fetched || 'recipe-leaf' === this.$route.name

    await this.$store.dispatch('recipes/meta', this.$route.meta)

    if (needsFetch) {
      await this.$store.dispatch('recipes/fetch', this.recipe)
    } else {
      await this.$nextTick()
    }

    this.$state.fetching = false

    this.setup()
  }

  setup() {
    return
  }
}
