


































































import { Getter } from 'vuex-class'
import { Component } from 'vue-property-decorator'
import { vueVimeoPlayer } from 'vue-vimeo-player'
import { RecipeState } from '@/store/modules/recipes/types'
import TimeIcon from '@/components/atoms/icons/Time.vue'
import DiffIcon from '@/components/atoms/icons/Diff.vue'
import BackBtn from '@/components/atoms/btns/Back.vue'
import Layout from '@/components/organsims/Layout.vue'
import Heading from '@/components/atoms/Heading.vue'
import Recipe from '@/mixins/Recipe'

@Component({
  inheritAttrs: false,
  components: {
    Vimeo: vueVimeoPlayer,
    TimeIcon,
    DiffIcon,
    BackBtn,
    Heading,
    Layout,
  },
})
export default class RecipeLeaf extends Recipe {
  @Getter('recipes/current') model!: RecipeState

  get section() {
    return this.$t('locale.recipes.title')
  }

  get videoStyle(): Partial<CSSStyleDeclaration> {
    const values = this.model.video.ratio.split('x')
    const width = parseInt(values[0])
    const height = parseInt(values[1])
    return {
      padding: `${(height / width) * 100}% 0 0`,
    }
  }
}
