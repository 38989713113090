


















































import { gsap } from 'gsap'
import { Getter } from 'vuex-class'
import { Component } from 'vue-property-decorator'
import { RecipeState, RecipeWidget as Widget } from '@/store/modules/recipes/types'
import ArticleWidget from '@/components/organsims/widgets/Article.vue'
import RecipesWidget from '@/components/organsims/widgets/Recipes.vue'
import RecipeWidget from '@/components/organsims/widgets/Recipe.vue'
import CoverWidget from '@/components/organsims/widgets/Cover.vue'
import Anchors from '@/components/molecules/Anchors.vue'
import Layout from '@/components/organsims/Layout.vue'
import Recipe from '@/mixins/Recipe'

@Component({
  components: {
    ArticleWidget,
    RecipesWidget,
    RecipeWidget,
    CoverWidget,
    Anchors,
    Layout,
  },
})
export default class Recipes extends Recipe {
  @Getter('recipes/widgets') widgets!: Widget[]
  @Getter('recipes/collection') collection!: RecipeState[]

  anchors = {
    main: -1,
  }

  $refs!: {
    widgets: any[]
  }

  filteredList = [] as RecipeState[]

  get navigation() {
    return this.widgets
      .map(({ theme: { anchor } }: any) => anchor)
      .filter((anchor: any) => anchor)
  }

  getWidgetId(anchor: any) {
    return anchor ? anchor.name : undefined
  }

  filterChange(id: number) {
    const filtered = this.collection.filter(({ tag }: any) => id === tag.id)
    this.filteredList = filtered.length ? filtered : this.collection
  }

  anchorTo({ main }: any) {
    const { name } = this.navigation[main]
    this.$state.needsLogo = false
    this.$fn.scrollTo(name)
  }

  scroll({ offset }: any) {
    const anchor = this.getCurrentAnchor(offset)
    const index = this.navigation.indexOf(anchor)
    this.anchors.main = index

    if (!this.$state.needsLogo && !this.$state.smoothScroll) this.$state.needsLogo = true
  }

  getCurrentAnchor(offset: any) {
    let result
    const { widgets } = this.$refs
    const { innerHeight: height } = window
    for (let i = 0; i < widgets.length; ++i) {
      const $widget = widgets[i].$el
      const y = $widget.offsetTop
      const offsetTop = y - offset.y
      if (offsetTop < height / 2) {
        const { anchor } = this.widgets[i].theme
        if (anchor) result = anchor
      }
    }
    return result
  }

  enter(el: HTMLElement, done: () => void) {
    const index = el.getAttribute('data-idx')
    const delay = parseInt(index as string) / 10
    gsap
      .timeline({ delay, onComplete: done, defaults: { duration: 1, ease: 'expo.out' } })
      .fromTo(el, { opacity: 0, y: 100 }, { opacity: 1, y: 0 })
  }

  leave(el: HTMLElement, done: () => void) {
    done()
  }

  setup() {
    this.filteredList = this.collection
  }
}
