













import { Component, Vue, Prop } from 'vue-property-decorator'
import Select from '@/components/atoms/form/Select.vue'

@Component({
  components: {
    Select,
  },
})
export default class RecipesWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean
}
