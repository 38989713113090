




















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DiffIcon extends Vue {
  @Prop() label!: string
}
