














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TimeIcon extends Vue {
  @Prop() label!: string
}
